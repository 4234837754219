@media screen and (min-width: 768px) and (max-width: 991px) {
#wrapper {
    min-width: 720px;
}
#header .inside,
#container,
#footer .inside {
    max-width: 720px;
    width: auto;
}
#header .inside {
    margin-left: auto !important;
    margin-right: auto !important;
}
.mobile_menu_trigger {
    left: 10px !important;
}
#header .right_box {
    right: 10px !important;
}
#main,
#left,
#right {
    float: none;
    width: auto;
}
#right {
    margin-right: 0;
}
#left {
    right: 0;
    margin-left: 0;
}
#container {
    padding-left: 0;
    padding-right: 0;
}
.person_list .ce_tvlyss_member {
    width: calc(50% - 20px) !important;
    float: left !important;
}
.mod_tvlyss_agenda .formbody_group {
    width: 226px !important;
    float: left !important;
    margin-right: 10px !important;
}
.mod_tvlyss_agenda .formbody_group:last-child {
    margin-right: 0 !important;
}
.mod_tvlyss_agenda .layout_list .image_container {
    width: 200px !important;
    float: left !important;
    margin-right: 20px !important;
    margin-bottom: 0 !important;
}
.mod_tvlyss_agenda .layout_list .inner {
    padding: 10px 20px 10px 58px !important;
}
}
