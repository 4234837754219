@media print {
#header .mod_navigation,
#header .right_box,
#header .mod_mobile_menu,
#left {
    display:none;
}
#header .inside,
#container,
#footer .inside {
    width:7.5in;
    margin-right:0;
    margin-left:0;
    padding-right:10px;
    padding-left:10px;
}
#header .logo {
    width:auto;
    float:none;
    margin-right:0;
}
#header .logo img {
    width:100px;
}
#main {
    float:none;
}
#right .icon_box {
    width:150px;
}
.mod_tvlyss_agenda .agenda_container .submit_container {
    display:none;
}
.mod_tvlyss_agenda .formbody_group {
    width:200px;
}
.mod_eventreader .ce_tvlyss_event_start.type_main,
.mod_eventreader .ce_tvlyss_event_start.type_right {
    width:auto;
    float:none;
    padding-right:0;
    padding-left:0;
}
.mod_eventreader p.back {
    display:none;
}
}
