@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?6rx6tm');
  src:  url('fonts/icomoon.eot?6rx6tm#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?6rx6tm') format('truetype'),
    url('fonts/icomoon.woff?6rx6tm') format('woff'),
    url('fonts/icomoon.svg?6rx6tm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
  content: "\e901";
}
.icon-alert:before {
  content: "\e900";
}
.icon-new-tab:before {
  content: "\e902";
}
.icon-file-pdf:before {
  content: "\e903";
}
.icon-search:before {
  content: "\e986";
}
.icon-facebook2:before {
  content: "\ea8d";
}
.icon-instagram1:before {
  content: "\ea92";
}
.icon-checkmark:before {
  content: "\e604";
}
.icon-minus:before {
  content: "\e602";
}
.icon-plus:before {
  content: "\e603";
}
.icon-list:before {
  content: "\e605";
}
.icon-arrow-left:before {
  content: "\e600";
}
.icon-arrow-right:before {
  content: "\e601";
}
