@media screen and (max-width: 767px) {
.ce_rsce_sponsors h2 {
    font-size: 20px;
    padding: 10px 0;
}
.sponsors {
    margin-bottom: 20px;
}
.sponsor {
    flex-direction: column;
}
.sponsor--platinum .sponsor__logo,
.sponsor--gold .sponsor__logo {
    max-width: 100%;
    flex: 0 0 100%;
    padding-right: 0;
    margin-bottom: 10px;
}
.sponsor__logo img {
    max-width: 200px;
}
.sponsor--silver .sponsor__logo,
.sponsor--bronze .sponsor__logo {
    padding: 0;
    margin-bottom: 10px;
    height: auto;
}
.sponsor--platinum .sponsor__content,
.sponsor--gold .sponsor__content {
    max-width: 100%;
    flex: 0 0 100%;
    flex-direction: column;
}
.sponsor--platinum .sponsor__address,
.sponsor--gold .sponsor__address {
    max-width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
    border: 1px dashed #bdb8a6;
}
.sponsor__name {
    text-align: center;
}
.sponsor__description {
    text-align: justify;
}
.sponsor__website {
    text-align: center;
}
.sponsor__address {
    font-size: 1em;
    text-align: center;
}
.sponsor--silver,
.sponsor--bronze {
    width: 100%;
    flex: 0 0 100%;
}
.sponsor--silver .sponsor__description,
.sponsor--bronze .sponsor__description {
    font-size: 1em;
}

    .mod_iso_checkout table,
    .mod_iso_cart table {
        tr {
            td {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.image {
                    max-width: 100px;
                    min-width: 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .mod_iso_messages .iso_confirm {
        text-align: center;
    }

    .mod_eventreader .layout_full .date {
        display: none;
    }

    .mod_iso_productlist {
        .product-list-group-name {
            text-align: center;
        }

        .product-wrapper {
            flex-wrap: wrap;
        }

        .product-header {
            flex-wrap: wrap;
            flex-direction: column;
            margin-bottom: .5rem;

            h3 {
                margin-bottom: .5rem;
            }

            .sku {
                margin-bottom: .5rem;
                margin-left: 0;
            }

            .offer {
                margin-left: 0;
            }
        }

        .product-footer {
            flex-wrap: wrap;
            justify-content: center;

            .quantity_container {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
                flex-direction: column;

                label {
                    margin-right: 0;
                }
            }
        }

        .product-body {
            fieldset {
                justify-content: center;
                text-align: center;
            }

            label {
                text-align: center;
            }
        }

        .product-gallery {
            margin-left: auto;
            margin-right: auto;
        }

        .product-content {
            width: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    .mod_iso_checkout,
    .mod_iso_cart {
        table {
            display: block;

            tbody {
                display: block;

                tr.product {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px dashed #827e77;
                }

                td {
                    display: block;
                    border-bottom: none;
                    padding: 0 !important;

                    &:not(:last-child) {
                        margin-bottom: .5rem;
                    }

                    &.quantity {
                        display: flex;
                        align-items: center;

                        &::before {
                            content: 'Anzahl:';
                            margin-right: .5rem;
                        }
                    }

                    &.price:not(.total)::before {
                        content: 'Preis:';
                        margin-right: .5rem;
                    }

                    &.price.total::before {
                        content: 'Total:';
                        margin-right: .5rem;
                    }
                }
            }

            tfoot {
                display: block;
                padding-bottom: 1rem;

                tr {
                    display: flex;
                    justify-content: center;
                    text-align: center;

                    td {
                        width: 50%;

                        &:first-child,
                        &.price:not(.total),
                        &.remove {
                            display: none;
                        }

                        &.name {
                            text-align: right;
                        }

                        &.price.total {
                            font-weight: bold;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .submit_container {
            flex-wrap: wrap;

            > *:not(:last-child) {
                margin-right: 0;
                margin-bottom: .5rem;
            }
        }
    }

    .mod_iso_checkout .address_new {
        margin-left: -10px;
        margin-right: -10px;

        > div {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
