@media screen and (min-width: 992px) {
    .mobile_menu {
        display: none !important;
    }

    /* CSS generated by Contao */
    body.layout_home {
        #left {
            width: 265px;
            right: 265px
        }

        #container {
            padding-left: 265px
        }
    }

    body.layout_2col {
        #left {
            width: 265px;
            right: 265px
        }

        #container {
            padding-left: 265px
        }
    }

    body.layout_2col_right {
        #right {
            width: 265px;
        }

        #container {
            padding-right: 265px
        }
    }

    body.layout_3col {
        #left {
            width: 265px;
            right: 265px
        }

        #right {
            width: 265px
        }

        #container {
            padding-left: 265px;
            padding-right: 265px
        }
    }
}
