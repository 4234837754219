@media screen and (max-width: 991px) {
body {
    font-size:16px;
    line-height:18px;
    -webkit-text-size-adjust: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height:1;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin-bottom:10px;
}
h2 {
    font-size:20px;
}
#wrapper {
    min-width:0;
    margin-bottom:20px;
}
#header {
    height:120px;
}
#header:before {
    width:100%;
    height:50px;
    left:0;
    top:0;
    position:absolute;
    background-color:#333;
    content: "";
    z-index: 1;
}
#header .inside {
    position:relative;
    margin-right:10px;
    margin-left:10px;
    z-index: 2;
}
#header .inside,
#container,
#footer .inside {
    width:auto;
}
#header .left_box {
    float:none;
}
#header .right_box {
    right:0;
    top:15px;
    position:absolute;
    float:none;
    padding-top:0;
}
#header .logo {
    width:120px;
    top:-50px;
    position:absolute;
    float:none;
    margin-right:0;
    margin-left:-60px;
    left: 50%;
}
#header .logo img {
    width: 100% !important;
}
#header .main_menu {
    display:none;
}
#header .facebook_link,
#header .instagram_link {
    margin-top:0;
    color:#fff;
}
#header .search_box {
    width:auto;
    height:auto;
    float:right;
    margin-right:0;
    margin-left:15px;
    padding:0;
    border:0;
    border-radius:0;
    background: none;
}
#header .search_box input {
    display:none;
}
#header .search_box button {
    position:static;
    color:#fff;
}
body.layout_2col:not(.shop-page) #left,
body.layout_3col:not(.shop-page) #left {
    display:none;
}
#left .inside,
#right .inside {
    margin-top:10px;
    padding-top:20px;
    border-top:1px dashed #827e77;
}
#left .mod_article > *,
#right .mod_article > * {
    margin-bottom:10px;
}
#left .mod_article > *:last-child,
#right .mod_article > *:last-child {
    margin-bottom:0;
}
#right .ce_image {
    padding-top: 20px;
    text-align: center;
}
#right .ce_image img {
    max-width: 200px;
}
#left .flag {
    display:none;
}
.dlh_googlemap {
    width: 100% !important;
    height: 200px !important;
}
.ce_tvlyss_member.last {
    margin-bottom:0;
}
.person_list .ce_tvlyss_member {
    width:auto;
    min-height:0;
    float:none;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mod_tvlyss_agenda .agenda_container {
    display: block;
}
.mod_tvlyss_agenda .agenda_container .agenda_form {
    min-width: auto;
    width: 100%;
    padding-right: 0;
}
.mod_tvlyss_agenda .agenda_container .agenda_events {
    width: 100%;
    padding-left: 0;
}
.mod_tvlyss_agenda .layout_list .inner {
    padding:0 10px 10px 58px;
}
.mod_tvlyss_agenda .layout_list .text_box {
    margin-left:0;
}
.mod_tvlyss_agenda .layout_list .image_container {
    width:auto;
    float:none;
    margin-right:0;
    margin-bottom:10px;
}
.mod_tvlyss_agenda .layout_list .image_container + .text_box {
    margin-left:0;
}
.mod_search > div {
    margin-bottom:16px;
}
.mod_eventreader .layout_full h1 {
    margin-top:0;
    margin-right:0;
    margin-bottom:10px;
}
.mod_eventreader .layout_full .date {
    margin-right: 0 !important;
}
.mod_eventreader .layout_full .badges_box {
    max-width:100%;
    float:none;
    padding-right:0;
}
.mod_eventreader .layout_full .info_box {
    max-width:none;
    float:none;
    margin-bottom:20px;
}
.mod_eventreader .ce_tvlyss_event_start.type_main,
.mod_eventreader .ce_tvlyss_event_start.type_right {
    width:auto;
    float:none;
    margin-right:0;
    margin-left:0;
    padding-right:0;
    padding-left:0;
}
.mod_article .mod_eventreader {
    margin-right:10px;
    margin-left:10px;
}
.mobile_menu_trigger {
    left:0;
    top:10px;
    position:absolute;
}
.mobile_menu_trigger span {
    font-size:34px;
    color:#fff;
}
.mobile_menu .inner {
    background-color:#514D4A;
    color:#FFF8E4;
}
.mobile_menu .inner a {
    text-decoration:none;
    color:#FFF8E4;
}
.mobile_menu .mod_navigation ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
.mobile_menu .mod_navigation ul li {
    margin:0;
    padding:0;
}
.mobile_menu .mod_navigation a,
.mobile_menu .mod_navigation strong {
    display:block;
    padding:10px 15px 10px 30px;
    background-color:#514D4A;
    border-bottom:1px dashed #FFF8E4;
}
.mobile_menu .mod_navigation a.trail,
.mobile_menu .mod_navigation strong {
    color: #ffdd22 !important
}
.mobile_menu .mod_navigation .level_2 a,
.mobile_menu .mod_navigation .level_2 strong {
    padding-left:30px;
    background-color:#3E3B39;
}
.mobile_menu .mod_navigation .level_3 a,
.mobile_menu .mod_navigation .level_3 strong {
    padding-left:45px;
    background-color:#312F2D;
}
.mobile_menu .mod_navigation .level_1 > li > a,
.mobile_menu .mod_navigation .level_1 > li > strong {
    position:relative;
}
.mobile_menu .mod_navigation .level_1 > li > a:before,
.mobile_menu .mod_navigation .level_1 > li > strong:before {
    left:3px;
    top:8px;
    position:absolute;
    font-size:24px;
    line-height:1;
    content: "\e601";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.mobile_menu .mod_navigation .level_1 > li > a.trail:before,
.mobile_menu .mod_navigation .level_1 > li > strong:before {
    content: "\e600";
}
.mod_tvlyss_ribbon {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    left:45px;
    top:8px;
    border-radius: 50%;
    position:absolute;
    font-size:24px;
    line-height:1;
    background: none;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.mod_tvlyss_ribbon a:hover {
    text-decoration: none !important;
}
.mod_tvlyss_ribbon .desktop {
    display:none;
}
.mod_tvlyss_ribbon .mobile {
    display:block;
}
.timeline {
    padding: 30px;
}
.timeline__item::after {
    left: -36.85px;
}
body.layout_home {
    #container {
        display: flex;
        flex-direction: column;
    }

    #main {
        order: 1;
    }

    #left .inside {
        margin: 0 10px 20px;
        padding: 0 0 20px 0;
        border-bottom: 1px dashed #827e77;
        border-top: none;
    }
}
    .shop-cart-link {
        display: block;
        position: fixed;
        right: 1rem;
        bottom: 1rem;
        margin: 0 !important;
        z-index: 10;

        a {
            display: flex;
            justify-content: center;
            width: 50px;
            height: 50px;
            align-items: center;
            background: #ffdd20;
            font-size: 1.2rem;
            text-decoration: none;
            border: 3px solid #514d4b;
            border-radius: 50%;
        }
    }
}
