@media screen and (min-width: 992px) and (max-width: 1199px) {
#wrapper {
    min-width: 960px;
}
#header .inside,
#container,
#footer .inside {
    max-width: 960px;
}
#header .right_box {
    position: absolute;
    top: 170px;
    right: 10px;
    float: none;
    padding-top: 0;
}
.ce_gallery ul,
.ce_facebook_album ul {
    margin-left: -5px;
    margin-right: -5px;
}
.ce_gallery ul li,
.ce_facebook_album ul li {
    width: calc(50% - 10px);
    margin: 5px !important;
}
.ce_tvlyss_member .image_box {
    width: 120px;
}
.ce_tvlyss_member .member_box.has_image .text_box {
    margin-left: 140px;
    padding-right: 20px;
}
}
